<template>
    <div class="videoPlayerArea">
                    
        <video  class="video" :src="url" />

        <div class="controls">
            <div @click="seeking" class="seekerVideoArea">
                <div class="seekerBodyVideo">
                    <div id="seekerVideo" class="seekerVideo"></div>
                </div>
            </div>
            <div class="controls-options">
                <div v-if="isPlaying == false" class="options-play">
                    <a href="" @click=" backWard"  @click.prevent="playPuase">
                        <font-awesome-icon :icon="['fa', 'backward']" />
                    </a>
                    <a href=""  @click.prevent="playPuase">
                        <font-awesome-icon :icon="['fa', 'play']" />
                    </a>

                    <a href="" @click=" forward"  @click.prevent="playPuase">
                        <font-awesome-icon :icon="['fa', 'forward']" />
                    </a>
                </div>
                <div v-if="isPlaying" class="options-play">
                    <a href="" @click="backWard"  @click.prevent="playPuase">
                        <font-awesome-icon :icon="['fa', 'backward']" />
                    </a>
                    <a href=""    @click.prevent="playPuase">
                        <font-awesome-icon :icon="['fa', 'pause']" />
                    </a>

                    <a href="" @click="forward"  @click.prevent="playPuase">
                        <font-awesome-icon :icon="['fa', 'forward']" />
                    </a>
                </div>
                <span>
                    <a href="">
                        <font-awesome-icon :icon="['fa', 'heart']" />
                    </a>
                    <a href="">
                        <font-awesome-icon :icon="['fa', 'share']" />
                    </a>
                </span>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>

@import '../../assets/scss/detail.scss';
@import '../../assets/scss/_videoPlayer.scss';

</style>

<script>

import converTimer from "../../helper/converteTime"

import  {onMounted, reactive, ref } from 'vue'

export default {

    props:["url","post"],

    setup(props){

        let video, seekerVideo
        let isPlaying = ref(false)

        const post = ref(props.post)

        onMounted(()=>{

            video =  document.querySelector('.video')
            video.addEventListener('timeupdate', updateTime);
            
        })

        const playPuase = ()=>{
            
            if(isPlaying.value){
                video.pause()
                isPlaying.value = false
            }else{
                video.play()
                isPlaying.value = true
            }
        }

        const timer = (element)=>{
            
            let hoursDuration = Math.floor(element.duration / 3600);
            let minutesDuration = Math.floor(element.duration / 60);
            let secondsDuration = Math.floor(((element.duration / 60) % 1) * 60);
            video.value = converTimer({hours: hoursDuration, minutes: minutesDuration, seconds:secondsDuration})
        
            let hours = Math.floor(element.currentTime / 3600);
            let minutes = Math.floor(element.currentTime / 60);
            let seconds = Math.floor(((element.currentTime / 60) % 1) * 60);
            video.value = converTimer({hours, minutes, seconds})
            
        }

        const updateTime = ()=>{

            seekerVideo = document.querySelector(".seekerVideo")
            const percent = (video.currentTime / video.duration) * 100;
            seekerVideo.style.width = String(percent)+'%';
            
            timer(video)

        }
        
        const seeking = (event)=>{
            let progress = (event.offsetX / event.target.offsetWidth)* video.duration
           
            video.currentTime = progress

        }

        const forward = ()=>{
            if(video.currentTime > 0){
                video.currentTime += 15
            }
        }

        const backWard = ()=>{
            if(video.currentTime > 0){
                video.currentTime -= 15
            }
        }
        

        return{playPuase, isPlaying, seeking, forward, backWard}

    }

}
</script>